import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { withFlags, type FlagsStateContainer } from '@confluence/flags';
import { usePubSubEvent } from '@confluence/pubsub-client';
import { useSessionData } from '@confluence/session-data';
import { VIEW_PAGE } from '@confluence/named-routes';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { usePageSpaceKey, usePageContentId } from '@confluence/page-context';

export const i18n = defineMessages({
	flagTitle: {
		id: 'live-pages-features.convert-to-live-page-flag.flagTitle',
		description:
			'Title for the flag that informs the user that the page is converted to a live page',
		defaultMessage: 'This page was switched to live editing',
	},
	switcherFlagTitle: {
		id: 'live-pages-features.convert-to-live-page-flag.switcherFlagTitle',
		description:
			'Title for the flag that informs the user that converted the page to live page that the option is success',
		defaultMessage: 'You’ve switched to live editing',
	},
	flagBody: {
		id: 'live-pages-features.convert-to-live-page-flag.flagBody',
		description:
			'Body for the flag that informs the user that the page is converted to a live page',
		defaultMessage: 'Updates to this page will now be instant and visible in real time.',
	},
	findOutMore: {
		id: 'live-pages-features.convert-to-live-page-flag.findOutMore',
		description:
			'Link on the convert to live edit flag direct a user to more information about live edit',
		defaultMessage: 'Find out more',
	},
	reloadButton: {
		id: 'live-pages-features.convert-to-live-page-flag.reloadButton',
		description: 'Button that causes a page reload and redirect the user to live edit page',
		defaultMessage: 'Reload',
	},
});

type ConvertToLivePageFlagProps = {
	flags: FlagsStateContainer;
};

const LEARN_MORE_URL =
	'https://support.atlassian.com/confluence-cloud/docs/create-and-collaborate-on-live-edit-pages-in-confluence/';

export const ConvertToLivePageFlag = withFlags(({ flags }: ConvertToLivePageFlagProps) => {
	const [spaceKey] = usePageSpaceKey();
	const [contentId] = usePageContentId();
	const intl = useIntl();
	const { replace } = useRouteActions();
	const isOnEditRoute = useIsEditorPage();
	const { userId } = useSessionData();

	const livePageLink = VIEW_PAGE.toUrl({
		spaceKey,
		contentId,
	});

	const handleOnClick = useCallback(() => {
		return isOnEditRoute ? replace(livePageLink) : window.location.reload();
	}, [isOnEditRoute, replace, livePageLink]);

	const showConvertToLivePageFlag = useCallback(
		(_, payload: any) => {
			const { accountId } = payload;
			void flags.showSuccessFlag({
				title:
					accountId === userId
						? intl.formatMessage(i18n.switcherFlagTitle)
						: intl.formatMessage(i18n.flagTitle),
				description: intl.formatMessage(i18n.flagBody),
				actions: [
					{
						content: <div data-testid="reload-button">{intl.formatMessage(i18n.reloadButton)}</div>,
						onClick: handleOnClick,
					},
					{
						content: (
							<div data-testid="find-out-more-button">{intl.formatMessage(i18n.findOutMore)}</div>
						),
						onClick: () => window.open(LEARN_MORE_URL, '_blank'),
					},
				],
			});
		},
		[intl, flags, handleOnClick, userId],
	);

	usePubSubEvent({
		// contentId might be undefined coming from usePageContentId, skipping pubsub event if so
		skip: !contentId,
		// Empty contentId will not happen as pubsub subscription will be skipped
		contentId: contentId ?? '',
		contentType: 'page',
		eventName: 'convertToLiveEdit:page',
		onEvent: showConvertToLivePageFlag,
	});

	return null;
});
