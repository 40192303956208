// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Container } from 'unstated';

type GuestSatisfactionSurveyState = {
	isSurveyTriggered: boolean;
	source: string;
};

const defaultState = {
	isSurveyTriggered: false,
	source: '',
};

export class GuestSatisfactionSurveyStateContainer extends Container<GuestSatisfactionSurveyState> {
	state = defaultState;

	triggerSurvey = ({ source }: { source: string }) =>
		void this.setState({
			isSurveyTriggered: true,
			source,
		});

	resetTrigger = () => {
		if (this.state.isSurveyTriggered) {
			// Don't change the source on reset, as the survey needs the source when it submits
			void this.setState((prevState) => ({
				isSurveyTriggered: false,
				source: prevState.source,
			}));
		}
	};
}
